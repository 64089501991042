<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布荣誉资质"/>
		<HonorForm @eventForm="handleGetForm"  class="purForm"/>
	</div>
</template>

<script>
	import HonorForm from '@/components/Honor/Form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('发布成功');
					this.$router.push('/honor')
				})
			}
		},
		components: {
			HonorForm
		}
	}
</script>
<style scoped>
	
</style>
